import React, { useState } from 'react';
import './Consultation.css';
import cons_off from '../images/off.png';
import cons_on from '../images/on.png';
import AppointmentForm from './AppointmentForm'; // Assume you have an AppointmentForm component


function Consultation() {
  const [selectedService, setSelectedService] = useState(null);

  const handleCardClick = (service) => {
    setSelectedService(service);
  };

  const handleClose = () => {
    setSelectedService(null);
  };
  return (
    <section className="consultation-section" id="appointment">
        <div className="consultation-card">
            <img src={cons_on} alt="Консультация онлайн" />
            <h3>Консультация онлайн, 2000&#8381;</h3>
            <p>Удобные и конфиденциальные онлайн-сессии из любой точки мира.</p>
            <button onClick={() => handleCardClick('online')} className='consultation-button-text'>Записаться</button>
        </div>
        <div className="consultation-card">
            <img src={cons_off} alt="Консультация очно" />
            <h3>Консультация очно, 2500&#8381;</h3>
            <p>Индивидуальные встречи в уютном офисе для обсуждения ваших вопросов и сеансы холистического биодинамического массажа (ХБМ).</p>
            <button onClick={() => handleCardClick('offline')} className='consultation-button-text'>Записаться</button>
        </div>
        {selectedService && <AppointmentForm service={selectedService} onClose={handleClose} />}
    </section>
  );
}

export default Consultation;