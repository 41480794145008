import './Header.css';
import React, { useState, useEffect, useRef  } from 'react';
import logo from '../images/logo_xxs.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faVk, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { HashLink} from 'react-router-hash-link';

function Header() {

    const menuRef = useRef();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Toggle the menu state
    };

    useEffect(() => {
        // Функция для проверки клика вне меню
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false); // Закрываем меню
            }
        }

        // Добавляем слушатель событий
        document.addEventListener('mousedown', handleClickOutside);

        // Очистка слушателя
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <header className="header">
            <div className="header-container"> 
            <div className="branding">
                <div className="logo">
                <HashLink smooth to="/">
                    <img src={logo} alt="Логотип" />
                </HashLink>
                </div>
                <div className="name-header">Людмила Валерьевна</div>
            </div>


            
            {isMobile ? (
                    <>
                        <div className="social-links">
                            <a href="https://t.me/zametki_psy" className="telegram-link">
                                <span>Мой Телеграм</span>
                                <FontAwesomeIcon icon={faTelegram} />
                            </a>
                        </div> 
                        <div className="hamburger" onClick={toggleMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        {menuOpen && (
                            <div ref={menuRef} className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
                                <nav>
                                    <HashLink smooth to="/#about" onClick={() => setMenuOpen(false)}>Обо мне</HashLink>
                                    <HashLink smooth to="/#services" onClick={() => setMenuOpen(false)}>Услуги и цены</HashLink>
                                    <HashLink smooth to="/#appointment" onClick={() => setMenuOpen(false)}>Запись</HashLink>
                                </nav>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="social-links">
                            <a href="https://t.me/zametki_psy" className="telegram-link">
                                <span>Мои заметки в Телеграм</span>
                                <FontAwesomeIcon icon={faTelegram} />
                            </a>
                        </div> 
                        <nav className="menu">
                            <HashLink smooth to="/#about">Обо мне</HashLink>
                            <HashLink smooth to="/#services">Услуги и цены</HashLink>
                            <HashLink smooth to="/#appointment">Запись</HashLink>
                        </nav>
                    </>
            )}
 
            </div>
        </header>
    );
}

export default Header;