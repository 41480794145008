import React from 'react';
import './Hero.css';
import photo from '../images/photo_sm.jpg';

function Hero() {
  return (
    <section className="hero">
        <div className="hero-content">
            <div className="hero-text">
                <h1>Откройте путь к гармонии и балансу в жизни</h1>
                <p>Добро пожаловать в мир понимания и самореализации. Я — Людмила, ваш личный психолог, гештальт-терапевт и специалист по биодинамическому холистическому массажу (ХБМ). Вместе мы найдём ключи к вашему внутреннему миру, поможем восстановить эмоциональное равновесие и откроем новые горизонты для личностного роста. Здесь вы найдёте безопасное пространство для разговора, исследования и лечения души.</p> 
                <p>Также я предлагаю уникальные сеансы ХБМ, которые позволят вам ощутить своё тело и его потребности, открывая двери к глубинной гармонии и благополучию. Погрузитесь в этот нежный и осознанный процесс вместе со мной, и почувствуйте полное единение тела и души.</p>
            </div>
            <div className="hero-image">
                <img src={photo} alt="Фотография" />
            </div>
        </div>
    </section>
  );
}

export default Hero;