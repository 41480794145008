// Footer.js

import React from 'react';
import './Footer.css';
import { FaWhatsapp, FaTelegramPlane, FaVk } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="name">Людмила Валерьевна</p>
        <p className="title">Гештальт-терапевт</p>
        <div className="social-links-footer">
          <a href="https://wa.me/79276657476" className="social-link-footer">
            <FaWhatsapp />
          </a>
          <a href="https://t.me/zametki_psy" className="social-link-footer">
            <FaTelegramPlane />
          </a>
          <a href="https://vk.com/club139522305" className="social-link-footer">
            <FaVk />
          </a>
        </div>
        <div className="contract-link">
          <a href="/contract">Контракт</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
