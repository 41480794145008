import React from 'react';
import { FaBrain, FaCertificate, FaHeart, FaBriefcase } from 'react-icons/fa';
import './Professional.css';

function Professional() {
    return (
        <section className="professional-section"  id="about">
            <h2 className="section-title">Квалификация</h2> 
            <div className="professional-content">
                <div className="professional-column">
                    <div className="professional-card">
                        <div className="professional-header">
                            <div className="professional-icon"><FaBrain /></div>
                            <div className="professional-title-text">
                                <h2 className="professional-title">Образование</h2>
                                <ul className="professional-list">
                                    <li className="professional-item">Высшее, по специальности "Дошкольная педагогика и психология". ЧГПУ, 2012</li>
                                    <li className="professional-item">Подготовка в направлении гештальт-терапии с 2022 по 2024.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="professional-card">
                        <div className="professional-header">
                            <div className="professional-icon"><FaBriefcase /></div>
                            <div className="professional-title-text">
                                <h2 className="professional-title">Мой опыт</h2>
                                <ul className="professional-list">
                                    <li className="professional-item">Психолог в ДОУ (2013 - 2015). Консультирование родителей, диагностика и практические занятия с детьми.</li>
                                    <li className="professional-item">Более пяти лет работы специалистом клиентской поддержки.</li>
                                    <li className="professional-item">Индивидуальные консультации и практика работы в тройках.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="professional-column">
                    <div className="professional-card">
                        <div className="professional-header">
                            <div className="professional-icon"><FaCertificate /></div>
                            <div className="professional-title-text">
                                <h2 className="professional-title">Продолжение образования</h2>
                                <ul className="professional-list">
                                    <li className="professional-item"><a href="/certificate">Дипломы и сертификаты о повышении квалификации</a></li>
                                    <li className="professional-item">Участие в качестве клиента в различных тренингах, расстановках и мастер-классах</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="professional-card">
                        <div className="professional-header">
                            <div className="professional-icon"><FaHeart /></div>
                            <div className="professional-title-text">
                                <h2 className="professional-title">Соблюдение профессиональных стандартов</h2>
                                <ul className="professional-list">
                                    <li className="professional-item">Личное развитие через терапию (более 50-ти часов).</li>
                                    <li className="professional-item">Постоянное обновление профессиональных знаний путём чтения специализированной литературы и исследований.</li>
                                    <li className="professional-item">Строгое соблюдение этических норм и конфиденциальности в работе с клиентами.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Professional;
