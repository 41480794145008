// App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link  } from 'react-router-dom';

import HomePage from './pages/HomePage';
import ContractPage from './pages/ContractPage';
import CertificatePage from './pages/CertificatePage';

import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/contract" element={<ContractPage />} />
          <Route path="/certificate" element={<CertificatePage />} />
        </Routes>
        <Footer /> 
      </div>
    </Router>
  );
}

export default App;
