import React from 'react';
import './AppointmentForm.css';
import { FaWhatsapp, FaTelegramPlane, FaEnvelope } from 'react-icons/fa';

function AppointmentForm({ service, onClose }) {
  const handleFormClick = (e) => {
    e.stopPropagation(); // Это предотвратит распространение клика на элементы за пределами формы
  };

  const whatsappText = encodeURIComponent(
    service === 'online' ?
    "Здравствуйте, меня интересует онлайн консультация. Хотелось бы узнать о свободных временных слотах и условиях проведения." :
    "Здравствуйте, меня интересует очная консультация. Хотелось бы уточнить возможные даты и время встречи, а также условия проведения."
  );

  const emailSubject = encodeURIComponent("Запрос на консультацию");
  const emailBody = encodeURIComponent(
    service === 'online' ?
    "Здравствуйте, меня интересует онлайн консультация. Хотелось бы узнать о свободных временных слотах и условиях проведения." :
    "Здравствуйте, меня интересует очная консультация. Хотелось бы уточнить возможные даты и время встречи, а также условия проведения."
  );

  return (
    <div className="appointment-form-modal" onClick={onClose}>
      <div className="appointment-form-container" onClick={handleFormClick}>
        <button className="close-button" onClick={onClose} aria-label="Close">&times;</button>
        <h2 className="form-title">Запись на приём</h2>
        <p className="service-type">{service === 'online' ? 'Онлайн консультация' : ''}</p>
        <div className="contact-buttons">
          <a href={`https://wa.me/79276657476?text=${whatsappText}`} className="contact-button whatsapp">
            <FaWhatsapp className="icon" />
            Записаться через Whatsapp
          </a>
          <a href={`https://t.me/psy_21mir`} className="contact-button telegram">
            <FaTelegramPlane className="icon" />
            Записаться через Telegram
          </a>
          <a href={`mailto:lyudapetr@mail.ru?subject=${emailSubject}&body=${emailBody}`} className="contact-button email">
            <FaEnvelope className="icon" />
            Написать письмо
          </a>
        </div>
      </div>
    </div>
  );
}

export default AppointmentForm;
