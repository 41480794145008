import React, { useState, useEffect } from 'react';

import MarqueeSlider from 'react-marquee-slider';
import diploma from '../images/certificates/diploma.jpg';
import AppSex from '../images/certificates/12220523.jpg';
import Socpsych from '../images/certificates/04062023.jpg';
import SymbolDramaA1 from '../images/certificates/CertificateA1.jpg';
import SymbolDramaA2 from '../images/certificates/CertificateA2.jpg';
import CertificateB1 from '../images/certificates/CertificateB1.jpg';
import Self from '../images/certificates/032014.jpg';
import Meta from '../images/certificates/Metaconsult.jpg';
import karty from '../images/certificates/Karty.jpg';
import './CertificatePage.css';
import { HashLink} from 'react-router-hash-link';

function CertificatePage() {
  const certificates = [
    { src: karty, alt: 'Метафорические карты' },
    { src: AppSex, alt: 'Прикладная сексология' },
    { src: Socpsych, alt: 'Психология в социальной сфере' },
    { src: diploma, alt: 'Диплом' },
    { src: Self, alt: 'Профессионально-личностное самоопределение' },
    { src: Meta, alt: 'Метаконсультации' },
    { src: SymbolDramaA1, alt: 'Введение в основы символдрамы А1' },
    { src: SymbolDramaA2, alt: 'Введение в основы символдрамы А2' },
    { src: CertificateB1, alt: 'Введение в основы символдрамы В3' },
    
    

  ];

  const [velocity, setVelocity] = useState(30); // Пример начальной скорости

  useEffect(() => {
    const updateVelocity = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setVelocity(3); // Меньшая скорость для мобильных устройств
      } else {
        setVelocity(20); // Обычная скорость для десктопов
      }
    };

    window.addEventListener('resize', updateVelocity);
    updateVelocity();

    return () => window.removeEventListener('resize', updateVelocity);
  }, []);

  return (
    <div className="certificate-page">
      <h1 className="certificate-title">Сертификаты и Дипломы</h1>
      <MarqueeSlider velocity={velocity}>
        {certificates.map((cert, index) => (
          <img key={index} src={cert.src} alt={cert.alt} />
        ))}
      </MarqueeSlider>
      <HashLink smooth to="/"><h2>Вернуться на главную страницу</h2></HashLink>
    </div>
  );
}

export default CertificatePage;
