// ContractPage.js
import { HashLink} from 'react-router-hash-link';
import React from 'react';
import './ContractPage.css';

function ContractPage() {
  return (
    <div className="contract-page">
      <article>
        <h2>Информированное Согласие</h2>
        <p>
          Настоящим подтверждаю своё добровольное согласие на консультационные услуги и принимаю следующие условия:
        </p>
        <ul>
          <li>Я даю согласие на индивидуальное консультирование в гештальт-подходе.</li>
          <li>Я понимаю, что консультирование строится на принципах сотрудничества и искренности.</li>
          <li>Я осознаю, что цель консультирования — помочь мне понять личные причины проблем, а также способы их решения и адаптации.</li>
          <li>У меня есть право отказаться от любых методик или упражнений в ходе сессий.</li>
          <li>Я уважаю индивидуальные особенности темпа и стиля психологической работы.</li>
          <li>Терапевт отвечает за внимательное наблюдение и предоставление обратной связи, ответственность за принятие решений остаётся за мной.</li>
          <li>Я обязуюсь своевременно оплачивать услуги и посещать сессии, а также соблюдать порядок завершения терапии.</li>
          <li>Консультирование не является медицинской услугой и не включает в себя медикаментозное лечение.</li>
          <li>Любая информация, полученная в ходе сеансов, является конфиденциальной и не подлежит разглашению.</li>
          <li>Я даю согласие на обработку моих персональных данных в рамках проведения консультаций.</li>
          <li>Длительность индивидуальной сессии составляет 50 минут</li>
        </ul>
      </article>
      <HashLink smooth to="/"><h2>Вернуться на главную страницу</h2></HashLink>
    </div>
  );
}

export default ContractPage;
