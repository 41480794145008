// HomePage.js
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Hero from '../components/Hero';
import Consultation from '../components/Consultation';
import Professional from '../components/Professional';
import Placeholder from '../components/Placeholder';
import Services from '../components/Services';
import Pricing from '../components/Pricing';
//import ContactForm from '../components/ContactForm';
import './HomePage.css';


function HomePage() {
  return (
    <div>
        <Hero />
        <main className="container content">   
            <Consultation />
            <Professional/>
            <Placeholder/>
            <Services/>
            <Pricing />
            {/* <ContactForm /> */}
        </main>
    </div>
  );
}

export default HomePage;
