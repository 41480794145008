import React from 'react';
import './Placeholder.css';
import chair from '../images/chair_md2.png';

function Placeholder() {
  return (
    <section className="placeholder">
        <div className="placeholder-content">
            <div className="placeholder-image">
                <img src={chair} alt="Фотография" />
            </div>
            <div className="placeholder-text">
                <h1>Путешествие в Мир Биодинамического Холистического Массажа</h1>
                <p>Откройте для себя биодинамический холистический массаж (ХБМ) – ваш путь к естественной гармонии и ритму тела. Техника построена на легких вибрациях и покачивании.</p>
                <p><strong>Что входит в методику ХБМ?</strong></p>
                <ul>
                  <li><strong>Техники холистического массажа:</strong> наслаждайтесь каждым прикосновением, которое помогает вашему телу вновь найти потерянное равновесие.</li>
                  <li><strong>Принципы краниосакральной биодинамики:</strong> проверенные временем методы для глубокой настройки вашего внутреннего "я".</li>
                  <li><strong>Подход 'Чистого языка':</strong> погружение в мир беседы с собой, где каждое слово и жест способствует глубинному пониманию собственных ощущений.</li>
                </ul>
                <p><strong>Зачем пробовать ХБМ?</strong> Научитесь ощущать и воспринимать части тела, которые обычно ускользают от внимания. Интерпретируйте "разговоры", которые ведёт ваше тело с вами, улавливая тончайшие его сигналы. Восстанавливайте гармонию и интеграцию вашего физического и эмоционального состояний, возвращая чувство целостности.</p>
                <p>Отправьтесь в путешествие внутрь себя с биодинамическим холистическим массажем и откройте новые горизонты вашего внутреннего мира.</p>
            </div>
        </div>
    </section>
  );
}

export default Placeholder;
