import React from 'react';
import './Services.css';

function Services() {
  const services = [
    { id: 1, title: 'Отношения с собой, саморазвитие', text: 'Работа над самооценкой и самопринятием может привести вас к более осознанной и полноценной жизни. Укрепление связи с самим собой — первый шаг к личному росту и благополучию.' },
    { id: 2, title: 'Отношения в паре', text: 'Гармоничные отношения в паре — основа для счастливой совместной жизни. Развиваем открытость и доверие, учимся вместе преодолевать трудности, чтобы ваш союз стал еще крепче и радостнее.' },
    { id: 3, title: 'Контакт с телом', text: 'Тело и разум тесно связаны. Работая с телесными ощущениями, мы можем добиться внутренней гармонии и эмоционального баланса. Познайте своё тело, чтобы жить полной жизнью.' },
    { id: 4, title: 'Биодинамический холистический массаж', text: 'Биодинамический холистический массаж (ХБМ) помогает восстановить гармонию между телом и умом. Эта техника сочетает глубокие расслабляющие приёмы с работой на эмоциональном уровне, обеспечивая целостное исцеление и ощущение единства. Откройте для себя мощь ХБМ, чтобы улучшить своё физическое состояние и эмоциональное благополучие.' },
    { id: 5, title: 'Профессиональная реализация', text: 'Профессиональный путь полон вызовов и возможностей для роста. Если вы ищете своё призвание или столкнулись с трудностями на текущем месте работы, я помогу вам найти и воплотить в жизнь вашу профессиональную мечту.' }
  ];

  return (
    <section className="service-cards" id="services">
      <h2>С какими запросами я могу вам помочь</h2>
      <div className="cards-container">
        {services.map(service => (
          <div key={service.id} className="card">
            <div className="card-content">
              <h3>{service.title}</h3>
              <p>{service.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
