import React, { useState } from 'react';
import './Pricing.css';
import { FaVideo, FaHandshake } from 'react-icons/fa'; // Updated icons
import AppointmentForm from './AppointmentForm'; // Assume you have an AppointmentForm component

function PricingSection() {
  const [selectedService, setSelectedService] = useState(null);

  const handleCardClick = (service) => {
    setSelectedService(service);
  };

  const handleClose = () => {
    setSelectedService(null);
  };

  return (
    <div className="pricing-section">
      <h2 className="pricing-title">Стоимость</h2>
      <div className="service-cards-container">
        <div className="service-card" onClick={() => handleCardClick('online')}>
          <FaVideo className="service-icon" />
          <h3 className="service-title">Онлайн консультация</h3>
          <p className="service-duration">1 час</p>
          <p className="service-price">2000 рублей</p>
          <p className="service-note">Записаться</p>
        </div>
        <div className="service-card" onClick={() => handleCardClick('offline')}>
          <FaHandshake className="service-icon" />
          <h3 className="service-title">Консультация в офисе, Чебоксары</h3>
          <p className="service-duration">50 минут</p>
          <p className="service-price">2500 рублей</p>
          <p className="service-note">Записаться</p>
        </div>
      </div>
      {selectedService && <AppointmentForm service={selectedService} onClose={handleClose} />}
    </div>
  );
}

export default PricingSection;